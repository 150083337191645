<template>
  <div>
    <v-card
      class="waiter-settings-work-schedules-calendar-wrapper"
      height="100vh"
    >
      <div
        v-if="refCalendar"
        class="calendar-header mx-6 my-5 d-flex align-center flex-wrap"
      >
        <v-btn
          v-if="closable"
          icon
          :ripple="false"
          @click="$emit('close')"
        >
          <v-icon>{{ icons.mdiWindowClose }}</v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-1"
          :ripple="false"
          @click="refCalendar.prev()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          class="me-3"
          :ripple="false"
          @click="refCalendar.next()"
        >
          <v-icon size="30">
            {{ icons.mdiChevronRight }}
          </v-icon>
        </v-btn>
        <p class="font-weight-semibold text-xl text--primary mb-0 me-6">
          {{ refCalendar.title.split(' ').reverse().join(' ') }} シフト
        </p>

        <v-spacer></v-spacer>

        <v-btn-toggle
          v-model="activeCalendarView"
          color="accent"
          class="mt-3 mt-sm-0"
        >
          <v-btn
            v-for="calendarViewOption in calendarViewOptions"
            :key="calendarViewOption"
            elevation="0"
            :ripple="false"
            :value="calendarViewOption"
          >
            {{ calendarViewOptionsLocale[calendarViewOption] }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <v-calendar
        ref="refCalendar"
        v-model="scheduleValue"
        color="accent"
        locale="ja-jp"
        :day-format="date => new Date(date.date).getDate()"
        :month-format="date => new Date(date.date).getMonth()+1+' /'"
        :type="activeCalendarView"
        :events="scheduleEvents"
        :event-text-color="getEventTextColor"
        :event-margin-bottom="6"
        @click:event="calenderHandleEventClick"
        @click:day="calendarHandleDayClick"
        @click:time="calendarHandleDayClick"
        @click:more="calendarHandleMoreClick"
        @click:date="calendarHandleMoreClick"
      />
    </v-card>

    <v-dialog
      v-model="isEventHandlerDialogActive"
      width="500"
    >
      <calendar-event-handler-dialog-content
        :event="selectedEvent"
        :users="users"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
        @close="clearEventData"
        @add-time-information="addTimeInfomation"
      />
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiPencilOutline,
  mdiTrashCanOutline,
  mdiWindowClose,
} from '@mdi/js'
import { getVuetify, hexToRgb } from '@core/utils'
import { cloneDeep } from 'lodash'
import WorkScheduleApi from '@/api/waiter/WorkSchedule'
import UserApi from '@/api/waiter/User'

// Local Components
import CalendarEventHandlerDialogContent from '@/views/components/calendar/CalendarEventHandlerDialogContent.vue'

import useDateFormatJS from '@/views/composable/useDateFormat'

export default {
  components: {
    CalendarEventHandlerDialogContent,
  },
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const $vuetify = getVuetify()

    const refCalendar = ref(null)
    const activeCalendarView = ref('day')
    const calendarViewOptions = ['month', 'week', 'day']
    const calendarViewOptionsLocale = {
      month: '月',
      week: '週',
      day: '日',
    }
    const scheduleValue = ref()
    const scheduleEvents = ref([])
    const blankEvent = {
      id: '',
      start: '',
      end: '',
      userId: '',
      userName: '',
    }
    const selectedEvent = ref(cloneDeep(blankEvent))
    const isEventHandlerDialogActive = ref(false)
    // TODO: Remove this once issue is resolved
    // Please check: https://github.com/vuetifyjs/vuetify/issues/13900
    const eventClickBugWorkaround = ref(false)
    const users = ref(false)

    const { dateTimeForJsFriendly } = useDateFormatJS()

    const clearEventData = () => {
      isEventHandlerDialogActive.value = false
      selectedEvent.value = cloneDeep(blankEvent)
    }

    const fetchEvents = async () => {
      const res = await WorkScheduleApi.getWorkSchedules()

      if (res?.data) {
        const schedules = res.data.work_schedules.data
        schedules.forEach(schedule => {
          /* eslint-disable no-param-reassign */
          schedule.start = dateTimeForJsFriendly(schedule.attributes.startAt)
          schedule.end = dateTimeForJsFriendly(schedule.attributes.endAt)
          schedule.userName = schedule.attributes.userName
          schedule.name = schedule.attributes.userName
          schedule.userId = schedule.attributes.userId

          const rgbColor = hexToRgb($vuetify.theme.currentTheme.success)
          schedule.color = `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 0.12)`
          schedule.eventTextColor = 'success'

          /* eslint-enable */
        })
        scheduleEvents.value = schedules
      }
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers(['cast', 'waiter'])

      if (res?.data) {
        users.value = [...res.data.users.data]
      }
    }

    const getEventTextColor = e => e.eventTextColor

    const calendarHandleDayClick = dayObj => {
      // Bug workaround
      if (eventClickBugWorkaround.value) {
        eventClickBugWorkaround.value = false

        return
      }

      selectedEvent.value.start = new Date(dayObj.date)
      selectedEvent.value.end = new Date(dayObj.date)
      isEventHandlerDialogActive.value = true
    }

    const calendarHandleMoreClick = ({ date }) => {
      scheduleValue.value = date

      activeCalendarView.value = 'day'

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    const calenderHandleEventClick = eventObj => {
      // Grab only event object
      selectedEvent.value = cloneDeep(eventObj.event)

      // NOTE: Safariはnew Date(‘yyyy-MM-dd HH:mm’)でエラーになるためハイフンではなく/に変換する（dateTimeForJsFriendly()したものをそんまま渡しせない）
      const { start, end } = selectedEvent.value
      selectedEvent.value.start = start.replace(/-/g, '/')
      selectedEvent.value.end = end.replace(/-/g, '/')

      isEventHandlerDialogActive.value = true

      // Bug workaround
      eventClickBugWorkaround.value = true
    }

    const addEvent = eventData => {
      // Only get date from event
      /* eslint-disable no-param-reassign */
      // TODO validation

      /* eslint-enable */
      WorkScheduleApi.createWorkSchedule(eventData).then(() => {
        // TODO: Next Update - Perform adding or refetching
        fetchEvents()
        vm.$toast.success('シフトを追加しました')
      })
    }

    const updateEvent = eventData => {
      WorkScheduleApi.updateWorkSchedule(eventData).then(() => {
        // TODO: Next Update - Perform adding or refetching
        fetchEvents()
        vm.$toast.success('シフトを更新しました')
      })
    }

    const addTimeInfomation = eventData => {
      /* eslint-disable no-param-reassign */
      eventData.start_at = eventData.start
      eventData.end_at = eventData.end
      /* eslint-enable */
    }

    const removeEvent = eventData => {
      WorkScheduleApi.removeWorkSchedule(eventData).then(() => {
        // TODO: Next Update - Perform adding or refetching
        fetchEvents()
        vm.$toast.success('シフトを削除しました')
      })
    }

    watch(isEventHandlerDialogActive, val => {
      if (!val) clearEventData()
    })

    fetchEvents()
    getUsers()

    return {
      // data
      refCalendar,
      calendarViewOptions,
      calendarViewOptionsLocale,
      scheduleValue,
      scheduleEvents,
      selectedEvent,
      isEventHandlerDialogActive,
      users,

      // methods
      getEventTextColor,
      activeCalendarView,
      clearEventData,
      calendarHandleDayClick,
      calendarHandleMoreClick,
      calenderHandleEventClick,
      addEvent,
      updateEvent,
      removeEvent,
      addTimeInfomation,

      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
.waiter-settings-work-schedules-calendar-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
.waiter-settings-work-schedules-calendar-wrapper::-webkit-scrollbar {
  display: none;
  overscroll-behavior: contain;
}
</style>
