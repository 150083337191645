import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

const addTimeInfomation = workScheduleRequest => {
  workScheduleRequest.push({
    key: 'start_at',
    value: workScheduleRequest.start,
  })
  workScheduleRequest.push({
    key: 'end_at',
    value: workScheduleRequest.end,
  })

  return workScheduleRequest
}

export default {
  getWorkSchedules() {
    const res = axiosIns.get('/waiter/work_schedules')

    return res
  },

  showWorkSchedule(workScheduleId) {
    return axiosIns.get(`/waiter/work_schedules/${workScheduleId}`)
  },

  createWorkSchedule(workScheduleRequest) {
    const formData = serialize(snakecaseKeys({ workSchedules: workScheduleRequest }))

    return axiosIns.post('/waiter/work_schedules', formData)
  },

  updateWorkSchedule(workScheduleRequest) {
    const formData = serialize(snakecaseKeys({ workSchedules: workScheduleRequest }))

    return axiosIns.patch(`/waiter/work_schedules/${workScheduleRequest.id}`, formData)
  },

  removeWorkSchedule(workScheduleRequest) {
    return axiosIns.delete(`/waiter/work_schedules/${workScheduleRequest.id}`)
  },
}
