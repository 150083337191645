<template>
  <v-card
    height="100%"
    class="pb-4 pr-7"
  >
    <v-form
      ref="refCalendarEventHandlerForm"
      class="pt-6"
      @submit.prevent="handleFormSubmit"
    >
      <v-card-text class="pl-7">
        <user-select
          :key="`calendar-event-handle-user-${Number(eventLocal.userId)}`"
          :users="users"
          :selected-user-id="Number(eventLocal.userId)"
          :with-query="false"
          :clearable="false"
          :prepend-icon="icons.mdiClipboardAccountOutline"
          prepend-inner-icon=""
          class="pt-4 pb-7"
          @selected="onUserSelect($event)"
        />

        <v-text-field
          ref="timecardStartAtInput"
          :value="dateTimeForJsFriendly(eventLocal.start)"
          label="出勤"
          type="datetime-local"
          :prepend-icon="icons.mdiClockOutline"
          class="pa-0 ma-0"
          @change="eventLocal.start = $event"
        />

        <v-text-field
          ref="timecardStartAtInput"
          :value="dateTimeForJsFriendly(eventLocal.end)"
          label="退勤"
          type="datetime-local"
          :prepend-icon="icons.mdiClockOutline"
          class="pa-0 ma-0"
          @change="eventLocal.end = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-expand-transition>
          <v-btn
            icon
            :ripple="false"
            @click="resetEventLocal"
          >
            <v-icon>{{ icons.mdiRefresh }}</v-icon>
          </v-btn>
        </v-expand-transition>
        <v-spacer />
        <v-btn
          :ripple="false"
          color="primary"
          :disabled="!isValid"
          type="submit"
        >
          {{ eventLocal.id ? '更新' : '追加' }}
        </v-btn>
        <v-btn
          color="error"
          outlined
          :ripple="false"
          @click="$emit('remove-event', eventLocal); $emit('close');"
        >
          <v-icon left>
            {{ icons.mdiTrashCanOutline }}
          </v-icon>
          削除
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiClockOutline,
  mdiRefresh,
  mdiClipboardAccountOutline,
} from '@mdi/js'
import { find, cloneDeep } from 'lodash'
import useDateFormat from '@/views/composable/useDateFormat'
import UserSelect from '@/views/components/util/filter/UserSelect.vue'

export default {
  components: {
    UserSelect,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { dateTimeForJsFriendly } = useDateFormat()

    const refCalendarEventHandlerForm = ref(null)
    const eventLocal = ref(cloneDeep(props.event))

    const isValid = computed(() => {
      const { value } = refCalendarEventHandlerForm
      if (!value) return false

      const { userId, start, end } = eventLocal.value

      return value.validate() && Number(userId) && new Date(start) < new Date(end)
    })

    const onUserSelect = id => {
      eventLocal.value.userId = id
      eventLocal.value.name = find(props.users, user => Number(user.id) === id)?.attributes.name
    }

    const resetEventLocal = () => {
      eventLocal.value = cloneDeep(props.event)

      // Reset Validation
      refCalendarEventHandlerForm.value?.resetValidation()
    }

    const handleFormSubmit = () => {
      if (!isValid.value) return

      emit('add-time-information', eventLocal.value)
      if (eventLocal.value.id) emit('update-event', eventLocal.value)
      else emit('add-event', eventLocal.value)

      emit('close')
    }

    watch(
      () => props.event,
      () => {
        resetEventLocal()
      },
      { deep: true },
    )

    return {
      // data
      refCalendarEventHandlerForm,
      eventLocal,

      // computed
      isValid,

      // methods
      onUserSelect,
      resetEventLocal,
      handleFormSubmit,
      dateTimeForJsFriendly,

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClockOutline,
        mdiRefresh,
        mdiClipboardAccountOutline,
      },
    }
  },
}
</script>
