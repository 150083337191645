import { computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { each } from 'lodash'
import querystring from 'querystring'

export default () => {
  const { router, route } = useRouter()

  const href = computed(() => {
    return router.resolve(route).href
  })

  const pushQueriesSilently = () => {
    window.history.pushState(
      {},
      null,
      querystring.stringify(route.value.query) ? `${href.value}?${querystring.stringify(route.value.query)}` : href.value,
    )
  }

  const replaceQueriesSilently = () => {
    window.history.replaceState(
      null,
      null,
      querystring.stringify(route.value.query) ? `${href.value}?${querystring.stringify(route.value.query)}` : href.value,
    )
  }

  const addQuery = (params = {}, replace = false) => {
    each(params, (val, key) => {
      route.value.query[key] = val
    })

    if (replace) {
      replaceQueriesSilently()
    } else {
      pushQueriesSilently()
    }
  }

  const removeQuery = (key, replace = false) => {
    if (Array.isArray(key)) {
      each([...key], k => delete route.value.query[k])
    } else {
      delete route.value.query[key]
    }

    if (replace) {
      replaceQueriesSilently()
    } else {
      pushQueriesSilently()
    }
  }

  const resetQuery = (replace = false) => {
    each(route.value.query, (_, key) => {
      delete route.value.query[key]
    })

    if (replace) {
      replaceQueriesSilently()
    } else {
      pushQueriesSilently()
    }
  }

  const buildQueryParams = (params = {}) => {
    return querystring.stringify(params)
  }

  return {
    router,
    route,
    addQuery,
    removeQuery,
    resetQuery,
    buildQueryParams,
  }
}
